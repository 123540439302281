<template>
  <div class="container">
    <perseu-card title="Alterar ordem dos tipos">
      <template #content>
        <v-list>
          <draggable :list="types">
            <template v-for="(type, index) in types">
              <v-list-item :key="type.id" class="elevation-2 mb-3">
                <v-list-item-action>
                  <v-icon>reorder</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  {{ index + 1 }} - {{ type.name }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </draggable>
        </v-list>
      </template>
      <template #actions>
        <v-btn color="secondary" outlined @click="back">Voltar</v-btn>
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </div>
</template>

<script>
import { find, updateOrder } from "@/services/types-service";
import draggable from "vuedraggable";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "Order",
  components: {
    draggable,
  },
  data: () => ({
    types: [],
  }),
  created() {
    this.fillList();
  },
  methods: {
    async fillList() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await find({ active: true });
        this.types = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        const formattedList = this.types.map((type, index) => ({
          id: type.id,
          order: index + 1,
        }));
        await updateOrder(formattedList);
        this.$toasted.global.defaultSuccess();
        this.$router.push({ name: pagesNames.TYPES_LIST });
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
